<template>
    <div class="page">
        <div class="flex between" style="margin-bottom: 24px;">
            <h5>{{$t('device.info')}}</h5>
            <div style="text-align: right;">
                <a-button v-if="userInfo.ifAdmin" :disabled="!userInfo.ifAdmin?true:!choiceUserInfo" type="primary" style="margin-right: 12px;" @click="batchImportData.show=true" >
                    {{$t('device.import')}}
                </a-button>
                <a-button :disabled="data.length===0" style="margin-right: 12px;" @click="exportDevice"  >{{$t('device.export')}}</a-button>
                <a-button style="margin-right: 12px;"
                          :disabled="selectedRows.length===0"
                          @click="groupAs.action(selectedRows)"  >{{$t('device.groupAs')}}</a-button>
                <a-button :disabled="selectedRows.length===0||!userInfo.ifAdmin" @click="delSelect">{{$t('device.del')}}</a-button>
            </div>
        </div>
        <a-table rowKey="sn" :scroll="{ x:1000, y: 400 }" :columns="columns" :data-source="data"
                 :pagination="pagination" :row-selection="rowSelection">
            <template #action="{record}">
                <a @click="()=>{groupAs.action([record])}">{{$t('device.groupAs')}}</a>
                <a-divider type="vertical"/>
                <a-popconfirm
                        v-if="userInfo.ifAdmin"
                        :title="$t('device.delPzl1')"
                        :ok-text="$t('ok')"
                        :cancel-text="$t('cancel')"
                        @confirm="()=>del([record])"
                >
                    <a  >{{$t('del')}}</a>
                </a-popconfirm>
                <a v-if="!userInfo.ifAdmin" disabled >{{$t('del')}}</a>
            </template>
        </a-table>
        <batch-import :info="batchImportData.info" :show="batchImportData.show" @close="batchImportData.show=false"/>
        <group-as-modal :info="groupAs.info" :show="groupAs.show" @close="groupAs.close"/>
    </div>
</template>

<script>
    import i18n from "../../../locale/main";
    import {reactive, createVNode, ref, computed,watch} from 'vue'
    import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
    import {Modal, message} from 'ant-design-vue';
    import {useStore} from 'vuex';

    import batchImport from './batchImport'
    import groupAsModal from './groupAs'
    import {exportDeviceOpen,deviceRemove} from '../../../service/device'
    export default {
        components:{
            batchImport,
            groupAsModal,
        },
        setup() {
            let t = i18n.global.t
            let store = useStore()
            const userInfo = computed(()=>store.state.user.userInfo)
          const choiceUserInfo = computed(()=>store.state.device.choiceUserInfo)
            const selectedRows = ref([])
            const batchImportData=reactive({
                show:false,
                info:{},
            })
            const groupAs=reactive({
                info: {},
                show:false,
                action(rows=[]){
                    groupAs.info=rows
                    groupAs.show=true
                },
                close(){
                    groupAs.show=false;
                    rowSelection.selectedRowKeys=[];
                    selectedRows.value=[]
                }
            })
            const columns = ref([
                {
                    title: t('device.sn'),
                    width: 300,
                    dataIndex: 'sn',
                    ellipsis:true,
                },
                {title: t('device.mac'), width: 180, dataIndex: 'mac',ellipsis:true,},
                {title: t('device.userAttr'), width: 140, dataIndex: 'user',ellipsis:true,},
                {title: t('createTime'), width: 200, dataIndex: 'createTime',ellipsis:true,},

                {
                    title: t('action'),
                    fixed: 'right',
                    width: 200,
                    slots: {customRender: 'action'},
                },
            ]);
            columns.value.map((r, k) => {
                r.key = k;
                return r
            })
            if(store.state.device.ifGroup){
                if(!columns.value.find(r=>r.dataIndex==='group')){
                    columns.value.splice(4,0, {title: t('device.group'),  dataIndex: 'group',ellipsis:true,})
                }
            }else{
                columns.value=columns.value.filter(r=>r.dataIndex!=='group')
            }
            watch(()=>store.state.device.ifGroup,()=>{
                if(!columns.value.find(r=>r.dataIndex==='group')){
                    columns.value.splice(4,0, {title: t('device.group'),  dataIndex: 'group',ellipsis:true,})
                }else{
                    columns.value=columns.value.filter(r=>r.dataIndex!=='group')
                }
            })


            const rowSelection = reactive({
                selectedRowKeys:[],
                onChange: (selectedRowKeys , rows) => {
                    rowSelection.selectedRowKeys = selectedRowKeys;
                    selectedRows.value = rows
                },
            });
            const pagination = reactive({
                current: computed(() => store.state.device.current),
                pageSize: computed(() => store.state.device.size),
                pageSizeOptions: store.state.device.sizeAll,
                total:computed(()=>store.state.device.total),
                showSizeChanger: true,
                showTotal: (total) => t('pages.total', {num: total}),
                async onChange(val) {

                    if(store.state.device.ifGroup){
                       await store.dispatch('device/search',{
                            current: val,
                            size: store.state.device.size,
                        })
                    }else{
                        await store.dispatch('device/searchNotGroup',{
                            current: val,
                            size: store.state.device.size,
                        })
                    }


                },
                async onShowSizeChange(_, size) {

                    if(store.state.device.ifGroup){
                       await store.dispatch('device/search',{
                            current: 1,
                            size: size,
                        })
                    }else{
                        await store.dispatch('device/searchNotGroup',{
                            current: 1,
                            size: size,
                        })
                    }

                },
            })

            const exportDevice=()=>{
                Modal.confirm({
                    title: t('device.export'),
                    icon: createVNode(ExclamationCircleOutlined),
                    content:  !store.state.device.groupId?t('device.exportPlz1'):t('device.exportPlz2'),
                    okText: t('ok'),
                    onOk(){

                        exportDeviceOpen(store.state.device.groupId,store.state.device.uid)
                        message.success(t('device.exportOk'))
                    },
                    cancelText: t('cancel'),
                });
            }
            const delSelect=()=>{
                Modal.confirm({
                    title: t('device.del'),
                    icon: createVNode(ExclamationCircleOutlined),
                    content: t('device.delPzl2',{num:selectedRows.value.length}),
                    okText: t('ok'),

                    async onOk() {
                        await del(selectedRows.value)
                        rowSelection.selectedRowKeys=[]
                    },
                    cancelText: t('cancel'),
                });
            }
            const del=async (rows=[])=>{
                await deviceRemove({ids:rows.map(r=>r.id)})
                if (store.state.device.ifGroup) {
                    await store.dispatch('device/search', {current: 1})
                } else {
                    await store.dispatch('device/searchNotGroup', {current: 1})
                }
                await store.dispatch('device/hasGroup')
                message.success(t('delOk'))
            }
            watch(()=>store.state.device.ifGroup,()=>{
                rowSelection.selectedRowKeys=[]
            })
            return {
              choiceUserInfo,
                rowActionStateFunc(row) {
                    console.log(row)
                },
                rowSelection,
                selectedRows,
                columns,
                pagination,
                batchImportData,
                exportDevice,
                del,
                delSelect,
                groupAs,
                userInfo,
                data: computed(() => store.state.device.data)
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>