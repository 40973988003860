<template>
    <a-modal :visible="show" width="800px" :title="$t('device.groupAs')" @cancel="handleCancel">

        <template #footer>
            <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">{{$t('ok')}}</a-button>
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules" :label-col="{span:4}"
                :wrapper-col="{span:18}">
            <a-form-item :label="$t('device.userAttr')" name="user">
                <a-select
                        ref="select"
                        :placeholder="$t('plz.select')"
                        style="width: 100%"
                        showSearch
                        :show-arrow="false"
                        :filter-option="false"
                        @search="handleSearch"
                        @change="userChange"
                        :options="formState.opts"
                        v-model:value="formState.user"/>
            </a-form-item>
            <a-form-item :label="$t('device.groupAttr')" name="group">
                <a-tree-select
                        :disabled="!formState.user"
                        v-model:value="formState.group"
                        style="width: 100%"
                        allowClear
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="formState.groups"
                        :placeholder="$t('plz.select')"
                        tree-default-expand-all/>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import {ref, reactive, toRaw, computed, watch,} from 'vue'
    import i18n from "../../../locale/main";
    import {message} from 'ant-design-vue'
    import {useStore} from "vuex";
    import {changeGroup} from '../../../service/device'

    export default {
        emits: ['close'],
        components: {},
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            info: {
                type: Object,
                default: null,
            },
        },
        setup(props, ctx) {
            let t = i18n.global.t
            let store = useStore()
            const formState = reactive({
                groups: computed(() => store.state.device.choiceGroupArr),
                user: props.info.uid,
                group: props.info.groupId,
                opts: computed(() => {
                    return store.state.user.data.map(r => {
                        r.label = r.attr
                        r.value = r.id
                        return r
                    })
                })
            })
            const formRef = ref();
            const loading = ref(false);
            const rules = {
                user: [{
                    required: true, validator: (rule, val) => {
                        if (!val) {
                            return Promise.reject(t('plz.select'))
                        }
                        return Promise.resolve()
                    }, trigger: 'blur',
                },],
                group: [{
                    required: true, validator: (rule, val) => {
                        if (!val) {
                            return Promise.reject(t('plz.select'))
                        }
                        return Promise.resolve()
                    }
                    , trigger: 'blur',
                },],
            }


            const handleOk = async () => {
                try {

                    await formRef.value.validate()
                    loading.value = true;
                    let data = toRaw(formState)
                    await changeGroup({deviceIds: props.info.map(r => r.id), groupId: data.group})
                    loading.value = false;

                    if (store.state.device.ifGroup) {
                        await store.dispatch('device/search', {current: 1})
                    } else {
                        await store.dispatch('device/searchNotGroup', {current: 1})
                    }

                    await store.dispatch('device/hasGroup')

                    ctx.emit('close', false)
                    message.success(t('actionOk'))
                    formRef.value.resetFields()
                } catch (e) {
                    loading.value = false;
                    console.log(e)
                }
            };

            const handleCancel = async () => {
                await store.dispatch('device/hasGroup')
                formRef.value.resetFields()
                ctx.emit('close', false)
            };
            watch(() => props.show, () => {
                if (props.show && props.info.length === 1) {
                    formState.group = props.info[0].groupId
                    formState.user = props.info[0].uid
                    store.dispatch('user/search', {searchForm: {search: ''}, current: 1,  search: true})
                    store.dispatch('device/changeChoiceGroupArr', {id: formState.user})
                }
            })


            return {
                loading,
                formRef,
                formState,
                rules,
                handleOk,
                handleCancel,
                async handleSearch(e){
                    await store.dispatch('user/search', {searchForm: {search: e}, current: 1, search: true})
                },
                async userChange(){
                    formState.group=null
                    await store.dispatch('device/changeChoiceGroupArr', {id: formState.user})
                },
            };
        }
    }
</script>

<style lang="scss" scoped>
    .upload-info {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;

        .icon {
            font-size: 28px;
            color: #1890FC;
        }
    }
</style>