<template>
    <a-modal :visible="show" width="800px" @cancel="handleCancel">
        <template #title>
            <a-tooltip>
                <template #title>
                    {{$t('batchImportPlz')}}
                </template>
                <span>{{$t('batchImport')}}</span>
                <InfoCircleFilled style="color: #929292;"/>
            </a-tooltip>
        </template>
        <template #footer>
            <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">{{$t('ok')}}</a-button>
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules" :colon="false" :label-col="{span:4}"
                :wrapper-col="{span:18}">
            <a-form-item :label="$t('device.userAttr')" name="user">
                <a-select
                        ref="select"
                        :placeholder="$t('plz.select')"
                        style="width: 100%"
                        :options="formState.opts"
                        allowClear
                        showSearch
                        :show-arrow="false"
                        :filter-option="false"
                        @search="handleSearch"
                        @change="userChange"
                        v-model:value="formState.user"/>
            </a-form-item>
            <a-form-item :label="$t('device.groupAttr')">
                <a-tree-select
                        :disabled="!formState.user"
                        v-model:value="formState.group"
                        style="width: 100%"
                        allowClear
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="formState.groups"
                        :placeholder="$t('plz.select')"
                        tree-default-expand-all/>
            </a-form-item>
            <a-form-item :label="$t('batchImport')" name="fileList">
                <div class="flex left align-right">
                    <a-upload-dragger
                            :beforeUpload="beforeUpload"
                            style="width: 230px;padding: 15px;"
                            v-model:fileList="formState.fileList"
                            name="file"
                            :multiple="false"
                            :showUploadList="false"
                            @change="handleChange">
                        <div v-if="formState.fileList.length===0" class="upload-info">
                            <inbox-outlined class="icon"/>
                            <div>
                                {{$t('upload.title')}}
                            </div>
                        </div>
                        <div v-else class="upload-info">
                            <FileDoneOutlined class="icon"/>
                            <div class="flex column">
                                <span v-if="formState.fileList[0].name.length>10" :title="formState.fileList[0].name">
                                    {{formState.fileList[0].name.slice(0,10)+'...'}}
                                </span>
                                <span v-else>
                                    {{formState.fileList[0].name}}
                                </span>
                                <a-button>{{$t('upload.reUp')}}</a-button>
                            </div>
                        </div>
                    </a-upload-dragger>
                    <a style="margin-left: 12px;" :href="tmp" download="template">{{$t('upload.tmpDow')}}</a>
                </div>
            </a-form-item>
        </a-form>

    </a-modal>
</template>

<script>
    import {ref, reactive, toRaw, computed, onMounted,watch} from 'vue'
    import i18n from "../../../locale/main";
    import {message} from 'ant-design-vue'
    import {InfoCircleFilled, InboxOutlined, FileDoneOutlined} from '@ant-design/icons-vue'
    import {useStore} from "vuex";
    import {importDevice} from '../../../service/device'
    import api from '../../../service/api'
    export default {
        emits: ['close'],
        components: {
            InfoCircleFilled,
            InboxOutlined,
            FileDoneOutlined,
        },
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            info: {
                type: Object,
                default: null,
            }
        },
        setup(props, ctx) {
            let t = i18n.global.t
            let store = useStore()
            const formState = reactive({

                fileList: [],
                groups: computed(() => store.state.device.choiceGroupArr),
                user: store.state.device.uid,
                group:store.state.device.groupId,
                opts: computed(() => {
                    return store.state.user.data.map(r => {
                        r.label = r.attr
                        r.value = r.id
                        return r
                    })
                })
            })
            const formRef = ref();
            const loading = ref(false);


            const rules = {
                user: [{
                    required: true, validator: (rule, val) => {
                        if (!val) {
                            return Promise.reject(t('plz.select'))
                        }
                        return Promise.resolve()
                    }, trigger: 'blur',
                },],
                fileList: [
                    {
                        required: true,
                        validator: (rule, val) => {
                            if (val.length === 0) {
                                return Promise.reject(t('device.importPlz'))
                            }
                            return Promise.resolve()
                        },
                        trigger: 'blur',
                    },
                ],
            }

            const handleChange = info => {
                const status = info.file.status;
                formState.fileList = [info.file]

                if (status === 'done') {
                    message.success(t('upload.success', {name: info.file.name}));
                } else if (status === 'error') {
                    message.error(t('upload.error', {name: info.file.name}));
                }

            };
            const beforeUpload = (file) => {
                let type = file.type
                if (type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    message.error(t('device.importPlz'))
                    return false
                }
                return false
            }

            const handleOk = async () => {
                try {

                    await formRef.value.validate()
                    loading.value = true;
                    let data = toRaw(formState)
                    await importDevice({file: data.fileList[0], groupId: data.group, uid: data.user})
                    loading.value = false;
                    ctx.emit('close', false)
                    message.success(t('upload.uploadOk'))
                    formRef.value.resetFields()
                    if (store.state.device.ifGroup) {
                        await store.dispatch('device/search', {current: 1})
                    } else {
                        await store.dispatch('device/searchNotGroup', {current: 1})
                    }
                    await store.dispatch('device/hasGroup')
                } catch (e) {
                    loading.value = false;
                    console.log(e)
                }
            };

            const handleCancel = async() => {
                await store.dispatch('device/hasGroup')
                formRef.value.resetFields()
                ctx.emit('close', false)
            };
            const ifAdmin=computed(()=>store.state.user.userInfo.ifAdmin)

            watch(()=> props.show,()=>{
                if(props.show){
                    formState.user=store.state.device.uid
                    formState.group=store.state.device.groupId
                  if(ifAdmin.value){
                    let searchName=''
                    if(store.state.device.choiceUserInfo){
                      searchName=store.state.device.choiceUserInfo.username
                    }
                    store.dispatch('user/search', {searchForm: {search: searchName}, current: 1,  search: true})
                    store.dispatch('device/changeChoiceGroupArr', {id: formState.user})
                  }
                }
            })

            onMounted(() => {
                if(ifAdmin.value){
                    store.dispatch('user/search', {searchForm: {search: ''}, current: 1,  search: true})
                }
            })
            return {
                tmp:api.device.tmp,
                loading,
                formRef,
                formState,
                rules,
                beforeUpload,
                handleOk,
                handleCancel,
                handleChange,
                async handleSearch(e){
                   await store.dispatch('user/search', {searchForm: {search: e}, current: 1, search: true})
                },
                async userChange() {
                    formState.group=null
                    await store.dispatch('device/changeChoiceGroupArr', {id: formState.user})
                }
            };
        }
    }
</script>

<style lang="scss" scoped>
    .upload-info {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;

        .icon {
            font-size: 28px;
            color: #1890FC;
        }
    }
</style>