<template>
    <div class="flex left align-left device" style="flex-wrap: nowrap;">
        <div class="group-box">
            <group-tree/>
        </div>
        <div class="info">
            <list-pge-view class="page-box">
                <template #search-view>
                    <h4 style="margin-bottom: 24px;">{{$t('device.title')}}</h4>
                    <search/>
                </template>
                <template #page-view>
                    <page/>
                </template>
            </list-pge-view>
        </div>
    </div>
</template>

<script>
    import listPgeView from "../../../layout/listPageView";
    import search from './search';
    import page from './page';
    import groupTree from './groupTree'

    export default {
        components: {
            listPgeView,
            search,
            page,
            groupTree,
        },

    }
</script>

<style lang="scss" scoped>
    .device {
        height: 850px;
        width: 100%;
        border-radius: 4px;
        overflow: hidden;

        .group-box {
            background: #ffffff;
            border: 1px solid #E3E4E6;
            width: 298px;
            height: 100%;
        }

        .info {
            width: calc(100% - 300px);
            height: 100%;
            background: #ffffff;
        }
    }
   .page-box{
      ::v-deep(.page-view){
          padding-top: 0;
      }
   }
</style>