<template>
    <div class="group">
        <div class=" flex between all-user">
            <h4>
                <a-popover v-if="ifAdmin" title="" placement="rightBottom" trigger="click" arrowPointAtCenter>
                    <template #content>
                        <div class="user-search-box">
                            <a-input-search v-model:value="group.searchUserVal" @search="group.searchUser"
                                            style="height: 32px;" :placeholder="$t('group.searchPzl2')"/>
                            <div class="result">
                                <div class="result-item" @click="group.choiceUser('all')">{{$t('group.all')}}</div>
                                <div class="result-item" v-for="(i,k) in group.allUser" :key="k"
                                     @click="group.choiceUser(i)">{{i.attr}}
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="flex left">
                        <div v-if="!group.currAttr">
                            {{$t('group.all')}}
                        </div>
                        <CaretRightFilled v-if="!group.currAttr"/>
                        <div v-if="group.currAttr" :title="group.currAttr?.attr">
                            {{group.currAttr?.attr.length>6?group.currAttr?.attr.slice(0,6)+'..':group.currAttr?.attr}}
                        </div>
                        <CaretDownFilled v-if="group.currAttr"/>
                    </div>
                </a-popover>
                <div v-else class="flex left">
                    <div v-if="!group.currAttr">
                        {{$t('group.all')}}
                    </div>
                    <CaretRightFilled v-if="!group.currAttr"/>
                    <div v-if="group.currAttr" :title="group.currAttr?.attr">
                        {{group.currAttr?.attr.length>6?group.currAttr?.attr.slice(0,6)+'..':group.currAttr?.attr}}
                    </div>
                    <CaretDownFilled v-if="group.currAttr"/>
                </div>
            </h4>
            <div class="group-show">
                <a-checkbox v-model:checked="group.show">{{$t('group.show')}}</a-checkbox>
            </div>
        </div>
        <div v-if="group.show" class="flex between group-opt">
            <a-input-search v-model:value="group.search" allowClear style="width: 150px;" :placeholder="$t('group.searchPzl')"/>
            <div class="flex right grow-1">
                <PlusOutlined @click="action.addFunc"/>
                <EditOutlined @click="action.editFunc"/>
                <DeleteOutlined @click="action.delFunc"/>
            </div>
        </div>
        <div class="group-list">
            <div :class="group.activeClass('noArr')" @click="group.changeCurrAttr('noArr')">{{$t('group.noArr')}}

            </div>
            <div v-if="!group.show" :class="group.activeClass('yesArr')" @click="group.changeCurrAttr('yesArr')">
                {{$t('group.yesArr')}}
            </div>
            <div v-if="group.show&&group.yesArr.length>0" class="group-tree">
                <a-tree
                        v-model:expandedKeys="expandedKeys"
                        v-model:selectedKeys="selectedKeys"
                        @select="group.changeCurrAttr"
                        blockNode
                        :auto-expand-parent="true"
                        :tree-data="group.yesArr">
                    <template #title="p">
                          <span v-if="p.title.indexOf(group.search) > -1">
                            {{ p.title.substr(0, p.title.indexOf(group.search)) }}<span style="color: #f50">{{ group.search }}</span>{{ p.title.substr(p.title.indexOf(group.search) + group.search.length) }}
                          </span>
                        <span v-else>{{ p.title }}</span>
                        <span> ({{p.dataRef.deviceNum}}) </span>
                    </template>
                </a-tree>
            </div>
        </div>

        <group-add :info="{currGroup:group.currGroup}" :show="action.addShow" @close="addClose"/>
        <group-edit :info="{currGroup:group.currGroup}" :show="action.editShow" @close="editClose"/>
    </div>
</template>

<script>
    import {reactive, computed, watch, ref,  createVNode,onMounted} from 'vue'
    import {useStore} from 'vuex'
    import {remove} from '../../../service/group'
    import {
        PlusOutlined,
        EditOutlined,
        DeleteOutlined,
        CaretRightFilled,
        CaretDownFilled,
        ExclamationCircleOutlined
    } from '@ant-design/icons-vue'
    import GroupAdd from "./groupAdd";
    import i18n from "../../../locale/main";
    import GroupEdit from "./groupEdit";
    import {message, Modal} from 'ant-design-vue'
    export default {
        components: {
            GroupEdit,
            GroupAdd,
            PlusOutlined,
            EditOutlined,
            DeleteOutlined,
            CaretRightFilled,
            CaretDownFilled,
        },
        setup() {
            let t = i18n.global.t
            let store = useStore()
            let expandedKeys = ref([])
            let selectedKeys = ref([])
            const ifAdmin=computed(()=>store.state.user.userInfo.ifAdmin)
            let action = reactive({
                addShow: false,
                editShow: false,
                async addFunc(){
                    await init()
                    action.addShow=true
                },
                editFunc(){
                    if(group.currGroup.name&&group.currGroup.name!=='noArr'&&group.currGroup.name!=='yesArr'){
                        action.editShow=true
                    }else{
                        message.error(t('group.err1'))
                    }

                },
                delFunc(){
                    if(group.currGroup.name&&group.currGroup.name!=='noArr'&&group.currGroup.name!=='yesArr'){
                        Modal.confirm({
                            title: t('group.del'),
                            icon: createVNode(ExclamationCircleOutlined),
                            content: t('group.delPlz1',{name:group.currGroup.title}),
                            okText: t('ok'),
                            async onOk() {
                                await remove({id:group.currGroup.name})
                                await fetchGet()
                                await store.dispatch('device/hasGroup')
                                message.success(t('delOk'))
                            },
                            cancelText: t('cancel'),
                        });
                    }else{
                        message.error(t('group.err1'))
                    }

                }
            })
            let group = reactive({
                show: true,
                currAttr: null,
                noArr: [],
                yesArr:computed(()=> store.state.device.yesGroup),
                currGroup: {
                    name: 'noArr',
                    title:t('group.noArr'),
                    pid:'',
                    uid:'',
                    groupId:'',
                },
                search: null,
                searchUserVal: null,
                searchUser(e) {
                    store.dispatch('user/search', {searchForm: {search: e}, current: 1,search:true},)
                    group.searchUserVal = null
                },
                activeClass: computed(() => {
                    return (name) => {
                        let curr = group.currGroup?.name
                        if (curr && curr === name) {
                            return 'flex left group-item active'
                        }
                        return 'flex left group-item'
                    }
                }),
                allUser: computed(() => store.state.user.data),
                async choiceUser(user) {
                    group.show=true
                    selectedKeys.value = []

                    if (user === 'all') {
                        group.currAttr = null


                        await store.dispatch('device/saveUid',{uid:null})
                        await store.dispatch('device/saveChoiceUserId',{uid:null})
                        await store.dispatch('device/setIfGroup',false)
                        await store.dispatch('device/saveUidGroupId', {groupId:null,uid:null})

                        group.searchUser('')
                        group.currGroup.uid=''
                    }else{
                        group.currAttr = user
                        group.currGroup.uid=user.id
                        await store.dispatch('device/saveUid',{uid:user.id})
                        await store.dispatch('device/saveChoiceUserId',{uid:user.id})
                        await store.dispatch('device/saveUidGroupId', {groupId:null,uid:user.id})
                    }
                    await fetchGet()

                    if(group.currGroup.name==='noArr'){
                        await store.dispatch('device/searchNotGroup',{current:1,uid:group.currAttr?.id})
                    }else if(group.currGroup.name==='yesArr'){
                        await store.dispatch('device/search',{current:1,uid:null,search:true})
                    }else{
                        await store.dispatch('device/search',{current:1,uid:group.currAttr?.id})
                    }


                },
                async changeCurrAttr(name, e) {
                    if (e&&e.selectedNodes&&e.selectedNodes.length>0) {
                        selectedKeys.value = name
                        group.currGroup.username = e.selectedNodes[0].props.dataRef.username
                        group.currGroup.name = e.selectedNodes[0].props.dataRef.id
                        group.currGroup.title =e.selectedNodes[0].props.dataRef.title
                        group.currGroup.pid =e.selectedNodes[0].props.dataRef.parentId
                        group.currGroup.uid =e.selectedNodes[0].props.dataRef.uid
                        await store.dispatch('device/setIfGroup',true)
                        await store.dispatch('device/saveUidGroupId', {groupId:group.currGroup.name,uid:e.selectedNodes[0].props.dataRef.uid})
                        await store.dispatch('device/search',{current:1,groupId:group.currGroup.name,uid:e.selectedNodes[0].props.dataRef.uid})
                    } else {
                        if(name instanceof Array){
                            name='noArr'
                        }
                        selectedKeys.value = []
                        group.currGroup.name = name
                        group.currGroup.title = t('group.'+name)
                        group.currGroup.username = ''
                        group.currGroup.pid=''
                        group.currGroup.uid=group.currAttr?.id
                        await store.dispatch('device/saveUidGroupId', {groupId:null,uid:group.currAttr?.id||null})
                        await store.dispatch('device/saveChoiceUserId',{uid:group.currAttr?.id||null})
                        if(name==='noArr'){
                            await store.dispatch('device/setIfGroup',false)
                            await store.dispatch('device/searchNotGroup',{current:1})
                        }else{
                            await store.dispatch('device/setIfGroup',true)
                            await store.dispatch('device/search',{current:1,groupId:null})
                        }

                    }
                }
            })

            const findTree = (name) => {
                let arr = []
                let loop = (title, findArr = []) => {
                    for (let item of findArr) {
                        if (item.title.indexOf(title) > -1) {
                            arr.push(item)
                        }
                        if (item.children && item.children.length > 0) {
                            loop(title, item.children)
                        }
                    }
                }
                loop(name, group.yesArr)
                return arr
            }

            watch(() => group.show, async() => {
                group.currGroup.name = "noArr"
                await group.changeCurrAttr('noArr',null)
                await fetchGet()
            })

            watch(() => group.search, () => {
                let arr = []
                if (!group.search) {
                    return expandedKeys.value = arr
                }
                findTree(group.search).map(r => {
                    if (r.pKey) {
                        arr.push(r.pKey)
                    }
                })
                expandedKeys.value = arr

            })


            const fetchGet=async ()=>{
                await store.dispatch('device/hasGroup')
                await store.dispatch('device/changeChoiceGroupArr',{id:group.currGroup.uid})
            }

            const init=async ()=>{
                if(!ifAdmin.value){
                    group.currAttr=store.state.user.userInfo
                    group.currGroup.uid=store.state.user.userInfo.id
                    await store.dispatch('device/saveUid',{uid:group.currGroup.uid})
                    await store.dispatch('device/saveChoiceUserId',{uid:group.currGroup.uid})
                }else{
                    await store.dispatch('device/saveUid',{uid:null})
                    await store.dispatch('device/saveChoiceUserId',{uid:null})
                }
                await fetchGet()
            }

            onMounted(async ()=>{

                init()

            })
            return {
                group,
                expandedKeys,
                selectedKeys,
                action,
                ifAdmin,
                async addClose(){
                    action.addShow=false
                    await init()
                },
                async editClose(){
                    action.editShow=false

                    await init()
                },
            }
        }
    }
</script>

<style lang="scss" scoped>
    .group {
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        position: relative;

        .all-user {
            cursor: pointer;
            padding: 20px 30px;
            border-bottom: 1px solid #E3E4E6;
        }

        h4 {
            > div {
                max-width: 120px;
            }
        }

        ::v-deep(.ant-tree li span.ant-tree-switcher, .ant-tree li span.ant-tree-iconEle){
           // width: 0;
        }
    }

    .group-show {
        ::v-deep(.ant-checkbox-wrapper) {
            color: #1890FC;
        }
    }

    .group-list {
        padding: 20px;

        .group-item {
            height: 40px;
            padding: 8px;
            cursor: pointer;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border-left: 2px solid transparent;
            transition: all 0.1s;

            &.active {
                border-left: 2px solid #1890FC;
                background: rgba(24, 144, 252, 0.1);
            }
        }
    }

    .group-opt {
        padding: 20px 20px 0 20px;

        .flex.right {
            span {
                margin-left: 10px;
                font-size: 20px;
                cursor: pointer;
            }

        }
    }

    .user-search-box {
        width: 480px;
        min-height: 500px;

        .result {
            padding: 20px 0;

            .result-item {
                padding: 4px;
                cursor: pointer;
                transition: all 0.3s;

                &:active,&:hover {
                    cursor: pointer;
                    background: rgba(24, 144, 252, 0.1);
                }
            }
        }
    }

    .group-tree {
        position: relative;

        ::v-deep(.ant-tree-node-selected) {
            border-left: 2px solid #1890FC;
            background: rgba(24, 144, 252, 0.1);
            height: 30px;
            padding: 2px 8px;
        }
    }

</style>