<template>
    <a-row :gutter="[48,24]" class="search-form">
        <a-col :sm="24" :xxl="10">
            <a-form-item :label="$t('device.searchDevice')" :colon="false">
                <a-input-search allowClear  v-model:value="formState.search" @search="formState.searchHandle" :placeholder="$t('device.searchDevicePlz')" />
            </a-form-item>
        </a-col>
        <a-col :sm="24" :xxl="14" class="flex between">
            <a-form-item :label="$t('date')" :colon="false">
                <a-range-picker style="width: 240px;"  @change="formState.searchHandle" v-model:value="formState.date" />
            </a-form-item>
            <a-button @click="formState.reset">{{$t('reset')}}</a-button>
        </a-col>
    </a-row>
</template>

<script>
    import {reactive, toRaw, } from 'vue'
    import {useStore} from "vuex";

    export default {
        setup(){
            let store = useStore()

            let formState=reactive({
                search:null,
                date:[],
                searchHandle(){
                    //console.log(toRaw(formState))
                    if(store.state.device.ifGroup){
                        store.dispatch('device/search',{searchForm:toRaw(formState),current:1,search:true})
                    }else{
                        store.dispatch('device/searchNotGroup',{searchForm:toRaw(formState),current:1,search:true})
                    }

                },
                reset(){
                    formState.search=null
                    formState.group=null
                    formState.date=[]

                    if(store.state.device.ifGroup){
                        store.dispatch('device/search',{searchForm:toRaw(formState),current:1,search:true})
                    }else{
                        store.dispatch('device/searchNotGroup',{searchForm:toRaw(formState),current:1,search:true})
                    }
                }
            })

            return {
                formState,
            }
        }
    }
</script>

<style lang="scss" scoped>
.search-form{
    ::v-deep(.ant-form-item){
        margin-bottom: 0;
    }
}
</style>