<template>
    <a-modal :visible="show" width="800px" @cancel="handleCancel">
        <template #title>
            <span>{{$t('group.edit')}}</span>
        </template>
        <template #footer>
            <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">{{$t('ok')}}</a-button>
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules"  :label-col="{span:4}"
                :wrapper-col="{span:18}">
            <a-form-item :label="$t('device.userAttr')" name="user">
                <a-select
                        ref="select"
                        :disabled="true"
                        :placeholder="$t('plz.select')"
                        style="width: 100%"
                        allowClear
                        showSearch
                        :show-arrow="false"
                        :filter-option="false"
                        @search="handleSearch"
                        :options="formState.opts"
                        v-model:value="formState.user"/>
            </a-form-item>
            <a-form-item :label="$t('group.name')" name="name">
                <a-input v-model:value="formState.name" :placeholder="$t('plz.input')"/>
            </a-form-item>
            <a-form-item :label="$t('group.pName')" name="pName">
                <a-tree-select
                        v-model:value="formState.pName"
                        style="width: 100%"
                        :disabled="true"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="formState.groups"
                        :placeholder="$t('plz.select')"
                        tree-default-expand-all/>
            </a-form-item>
        </a-form>

    </a-modal>
</template>

<script>
    import {ref, reactive, toRaw, watch, computed} from 'vue'
    import i18n from "../../../locale/main";
    import {message} from 'ant-design-vue'
    import {update} from '../../../service/group'
    import {useStore} from "vuex";
    export default {
        emits: ['close'],
        components: {
        },
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            info: {
                type: Object,
                default: null,
            }
        },
        setup(props, ctx) {
            let t = i18n.global.t
            let store = useStore()
            const formState = reactive({

                user: null,
                name: null,
                pName: 'root',
                opts: computed(()=>{
                    return store.state.user.data.map(r=>{
                        r.label=r.attr
                        r.value=r.id
                        return r
                    })
                }),
                groups: computed(() => {
                    let arr=store.state.device.choiceGroupArr
                    if(!arr.find(r=>r.key==='root')){
                        arr.unshift({
                            title: t('group.root'),
                            key: 'root',
                            value: 'root',
                            pKey: '',
                            uid:formState.user,
                            rootTag: 0,
                        })
                    }
                    return arr
                }),
            })
            const formRef = ref();
            const loading = ref(false);


            const rules = {
                user: [{required: true,validator: (rule, val) => {
                        if (!val) {
                            return Promise.reject(t('plz.select'))
                        }
                        return Promise.resolve()
                    },trigger: 'blur',},],
                name: [{required: true,validator:(rulue,val)=>{
                        if (!val) {
                            return Promise.reject(t('plz.input'))
                        }
                        if (val.length>12) {
                            return Promise.reject(t('plz.inputLimit1'))
                        }
                        return Promise.resolve()
                    },trigger: 'blur',},],
                pName: [{required: true,
                    validator: (rule, val) => {
                        if (!val) {
                            return Promise.reject(t('plz.select'))
                        }
                        return Promise.resolve()
                    },
                    trigger: 'blur',},],
            }



            const handleOk = async () => {
                try {

                    await formRef.value.validate()
                    loading.value = true;
                    let data=toRaw(formState)
                    await update({
                        id:props.info.currGroup.name,
                        groupName:data.name,
                    })
                    loading.value = false;
                    message.success(t('actionOk'))

                    if (store.state.device.ifGroup) {
                        await store.dispatch('device/search', {current: 1})
                    } else {
                        await store.dispatch('device/searchNotGroup', {current: 1})
                    }

                    await store.dispatch('device/hasGroup')
                    formRef.value.resetFields()
                    ctx.emit('close', false)
                } catch (e) {
                    loading.value = false;
                    console.log(e)
                }
            };

            const handleCancel = () => {
                formRef.value.resetFields()
                ctx.emit('close', false)
            };

            watch(()=>props.show,()=>{
                if(props.show){
                    if(props.info.currGroup.name==='noArr'||props.info.currGroup.name==='yesArr'){
                        formState.pName='root'
                    }else{
                        formState.name=props.info.currGroup.title
                        if(props.info.currGroup.pid===0){
                            formState.pName='root'
                        }else{
                            formState.pName=props.info.currGroup.pid
                        }
                        formState.user=props.info.currGroup.uid
                        store.dispatch('device/changeChoiceGroupArr', {id: formState.user})
                    }
                }
            })


            return {
                loading,
                formRef,
                formState,
                rules,
                handleOk,
                handleCancel,
                async handleSearch(e){
                    await store.dispatch('user/search', {searchForm: {search: e}, current: 1, search: true})
                },
            };
        }
    }
</script>

<style lang="scss" scoped>
    .upload-info {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;

        .icon {
            font-size: 28px;
            color: #1890FC;
        }
    }
</style>