<template>
    <a-modal :visible="show" width="800px" @cancel="handleCancel">
        <template #title>
            <a-tooltip>
                <template #title>
                    <div>{{$t('group.addPlz1')}}</div>
                </template>
                <span>{{$t('group.add')}}</span>
                <InfoCircleFilled style="color: #929292;"/>
            </a-tooltip>
        </template>
        <template #footer>
            <a-button key="back" @click="handleCancel">{{$t('cancel')}}</a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">{{$t('ok')}}</a-button>
        </template>
        <a-form ref="formRef" :model="formState" :rules="rules"  :label-col="{span:4}"
                :wrapper-col="{span:18}">
            <a-form-item :label="$t('device.userAttr')" name="user">
                <a-select
                        :disabled="ifAdmin?false:formState.pName!=='root'?true:false"
                        ref="select"
                        :placeholder="$t('plz.select')"
                        style="width: 100%"
                        :filter-option="false"
                        allowClear
                        showSearch
                        :show-arrow="false"
                        @search="handleSearch"
                        @change="userChange"
                        :options="formState.opts"
                        v-model:value="formState.user"/>
            </a-form-item>
            <a-form-item :label="$t('group.name')" name="name">
                <a-input v-model:value="formState.name" :placeholder="$t('plz.input')"/>
            </a-form-item>
            <a-form-item :label="$t('group.pName')" name="pName">
                <a-tree-select
                        v-model:value="formState.pName"
                        :disabled="!formState.user"
                        style="width: 100%"
                        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                        :tree-data="formState.groups"
                        :placeholder="$t('plz.select')"
                        @select="selectGroup"
                        tree-default-expand-all/>
            </a-form-item>
        </a-form>

    </a-modal>
</template>

<script>
    import {ref, reactive, toRaw, watch, computed, onMounted} from 'vue'
    import i18n from "../../../locale/main";
    import {message} from 'ant-design-vue'
    import {InfoCircleFilled} from '@ant-design/icons-vue'
    import {useStore} from "vuex";
    import {add} from '../../../service/group'
    export default {
        emits: ['close'],
        components: {
            InfoCircleFilled,
        },
        props: {
            show: {
                type: Boolean,
                default: false,
            },
            info: {
                type: Object,
                default: null,
            }
        },
        setup(props, ctx) {
            let t = i18n.global.t
            let store = useStore()
            const formState = reactive({
                groups: computed(() => {
                    let arr=[...store.state.device.choiceGroupArr]
                    if(!arr.find(r=>r.key==='root')){
                        arr.unshift({
                            title: t('group.root'),
                            key: 'root',
                            value: 'root',
                            pKey: '',
                            uid:formState.user,
                            rootTag: 0,
                        })
                    }
                    return arr
                }),
                user: null,
                name: null,
                pName: 'root',
                opts: computed(()=>{
                    return store.state.user.data.map(r=>{
                        r.label=r.attr
                        r.value=r.id
                        return r
                    })
                })
            })
            const formRef = ref();
            const loading = ref(false);


            const rules = {
                user: [{required: true,validator: (rule, val) => {
                        if (!val) {
                            return Promise.reject(t('plz.select'))
                        }
                        return Promise.resolve()
                    },trigger: 'blur',},],
                name: [{required: true,validator:(rulue,val)=>{
                        if (!val) {
                            return Promise.reject(t('plz.input'))
                        }
                        if (val.length>12) {
                            return Promise.reject(t('plz.inputLimit1'))
                        }
                        return Promise.resolve()
                    },trigger: 'blur',},],
                pName: [{required: true,
                    validator: (rule, val) => {
                        if (!val) {
                            return Promise.reject(t('plz.select'))
                        }
                        return Promise.resolve()
                    },
                    trigger: 'blur',},],
            }



            const handleOk = async () => {
                try {

                    await formRef.value.validate()
                    loading.value = true;
                    let data=toRaw(formState)
                    console.log(data)
                    await add({
                        groupName: data.name,
                        parentId: data.pName==='root'?0:data.pName,
                        uid: data.user,
                    })

                    loading.value = false;
                    message.success(t('actionOk'))
                    await store.dispatch('device/hasGroup')
                    formRef.value.resetFields()
                    ctx.emit('close', false)
                } catch (e) {
                    loading.value = false;
                    console.log(e)
                }
            };

            const handleCancel = () => {
                formRef.value.resetFields()
                ctx.emit('close', false)
            };
            const userChange=async ()=>{
                formState.pName=null
                await store.dispatch('device/changeChoiceGroupArr', {id: formState.user})
            }



            watch(()=>props.show,async()=>{
                if(props.show){
                    formState.user=props.info.currGroup.uid
                    await store.dispatch('device/changeChoiceGroupArr', {id: formState.user})
                    if(props.info.currGroup.name&&props.info.currGroup.name!=='noArr'){
                        formState.pName=props.info.currGroup.name
                    }
                }
            })

            const ifAdmin=computed(()=>store.state.user.userInfo.ifAdmin)
            onMounted(()=>{
                if(ifAdmin.value){
                    store.dispatch('user/search',{searchForm:{search:''},current:1,search:true})
                }
            })

            return {
                loading,
                formRef,
                formState,
                rules,
                handleOk,
                handleCancel,
                ifAdmin,
                selectGroup(id){
                    formState.pName=id
                },
                async handleSearch(e){
                    await store.dispatch('user/search', {searchForm: {search: e}, current: 1, search: true})
                },
                userChange,
            };
        }
    }
</script>

<style lang="scss" scoped>
    .upload-info {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;

        .icon {
            font-size: 28px;
            color: #1890FC;
        }
    }
</style>